import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <footer className="App-footer">
        <a href="https://beian.miit.gov.cn/" target="_blank">
          京ICP备17030858号
        </a>
      </footer>
    </div>
  );
}

export default App;
